import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterState, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard {

  constructor(private authService: AuthService, private router: Router) { }

  // canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
  //   if (this.authService.isLoggedIn()) {
  //     return true;
  //   }

  //   // not logged in so redirect to login page with the return url and return false
  //   this.router.navigate(['login'], { queryParams: { returnUrl: routerState.url } });
  //   return false;
  // }

  // canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
  //   if (this.authService.isLoggedIn()) {
  //     return true;
  //   }

  //   // not logged in so redirect to login page with the return url and return false
  //   this.router.navigate(['login'], { queryParams: { returnUrl: routerState.url } });
  //   return false;
  // }

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    this.authService.isLoggedIn().pipe(
      tap(v => {
        if (!v) {
          this.router.navigate(['login'], { queryParams: { returnUrl: routerState.url } });
        }
      }
      )).subscribe();

    return this.authService.isLoggedIn();
  }

  canActivateChild(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    this.authService.isLoggedIn().pipe(
      tap(v => {
        if (!v) {
          this.router.navigate(['login'], { queryParams: { returnUrl: routerState.url } });
        }
      }
      )).subscribe();

    return this.authService.isLoggedIn();
  }
}
